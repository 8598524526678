import { WebRTCPlayer, ListAvailableAdapters } from '../src/index';

import { Decrypt } from '../src/decrypt'

interface PacketsLost {
  [type: string]: number;
}

async function getChannels(broadcasterUrl: string) {
  const response = await fetch(broadcasterUrl + '/channel');
  if (response.ok) {
    const channels = await response.json();
    return channels;
  }
  return [];
}



window.addEventListener('DOMContentLoaded', async () => {
  const input = document.querySelector<HTMLInputElement>('#channelUrl');
  const video = document.querySelector('video');
  const inputContainer = document.querySelector<HTMLDivElement>('#input');
  const adapterContainer = document.querySelector<HTMLDivElement>('#adapters');
  const inputPrerollUrl =
    document.querySelector<HTMLInputElement>('#prerollUrl');

 let inputurl=''

  const param = window.location.href.split("endpoint=")
  if (param.length> 0) {
    const cryptClass = new Decrypt(param[1])
    const url = cryptClass.decrypt()
    inputurl = new URL(url);
  }

  if (!inputurl || inputurl.length==0) {
    return;
  }

  inputContainer.style.display = 'block';
  

  let iceServers: RTCIceServer[];

  let player: WebRTCPlayer;

  const playButton = document.querySelector<HTMLButtonElement>('#play');
  playButton?.addEventListener('click', async () => {
    const channelUrl = inputurl;
    const vmapUrlElem = document.querySelector<HTMLInputElement>('#preroll');
    const vmapUrl = undefined;
    if (video) {
      player = new WebRTCPlayer({
        video: video,
        type: 'whep',
        iceServers: iceServers,
        debug: true,
        vmapUrl: vmapUrl,
        statsTypeFilter: '^candidate-*|^inbound-rtp'
      });
    }

    const packetsLost: PacketsLost = { video: 0, audio: 0 };

    player.on('stats:candidate-pair', (report) => {
      const currentRTTElem =
        document.querySelector<HTMLSpanElement>('#stats-current-rtt');
      const incomingBitrateElem = document.querySelector<HTMLSpanElement>(
        '#stats-incoming-bitrate'
      );
      if (report.nominated && currentRTTElem) {
        currentRTTElem.innerHTML = `RTT: ${
          report.currentRoundTripTime * 1000
        }ms`;
        if (report.availableIncomingBitrate && incomingBitrateElem) {
          incomingBitrateElem.innerHTML = `Bitrate: ${Math.round(
            report.availableIncomingBitrate / 1000
          )}kbps`;
        }
      }
    });
    player.on('stats:inbound-rtp', (report) => {
      if (report.kind === 'video' || report.kind === 'audio') {
        const packetLossElem =
          document.querySelector<HTMLSpanElement>('#stats-packetloss');
        packetsLost[report.kind] = report.packetsLost;
        if (packetLossElem) {
          packetLossElem.innerHTML = `Packets Lost: A=${packetsLost.audio},V=${packetsLost.video}`;
        }
      }
    });

    await player.load(new URL(channelUrl));
  });

  const stopButton = document.querySelector<HTMLButtonElement>('#stop');
  stopButton?.addEventListener('click', async () => {
    await player.unload();
  });
});
