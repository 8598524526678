import cryptoJs from "crypto-js";

const { AES, enc } = cryptoJs;

const { decrypt } = AES;

export class Decrypt{
    key: any
    endpoint: any
    constructor(endpoint:any){
        this.endpoint = endpoint
        this.key = "AssAssInsCreedIIIII"
    }

    decrypt(){
        const decryptedData = decrypt(this.endpoint, this.key).toString(enc.Utf8);
        return decryptedData
    }
}